import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { useIntl, changeLocale, Link } from "gatsby-plugin-intl";

const LANG_MAP = {
  en: "English",
  de: "Deutsch",
  "zh-CN": "中文",
};

function Header() {
  const intl = useIntl();
  const [isExpanded, toggleExpansion] = useState(false);

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const targetLocale = intl.locale === "en" ? "zh-CN" : "en";
  const targetLang = LANG_MAP[targetLocale];

  const nav = (
    <nav className={`${isExpanded ? `block` : `hidden`} md:block flex w-full`}>
      {[
        {
          route: `/about`,
          title: intl.formatMessage({ id: "about" }),
        },
      ].map((link) => (
        <a
          className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
          key={link.title}
          title={link.title}
          href={`/${intl.locale}${link.route}`}
          target="_blank" // eslint-disable-line react/jsx-no-target-blank
          rel="noreferrer"
        >
          {link.title}
        </a>
      ))}
    </nav>
  );
  return (
    <header className="bg-gray-900">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-white no-underline">
            <span className="text-xl font-bold tracking-tight">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <div className={"flex items-end"}>
          <button
            className="flex items-center block px-3 py-2 text-white border border-white rounded md:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          {/* Display nav here when it's not expanded */}
          {isExpanded ? null : nav}

          <button
            className="block px-4 text-white flex items-center font-bold hover:underline"
            style={{
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              if (
                typeof window !== "undefined" &&
                window.location.pathname.endsWith("/peformance/")
              ) {
                if (
                  !confirm(
                    "你确定要切换语言吗？已经开始的直播会被中止。\n\nAre you sure you want to change the language? This will interrupt the performance if the streaming has started."
                  )
                )
                  return;
              }

              changeLocale(targetLocale);
            }}
          >
            {targetLang}
          </button>
        </div>

        {/* Nav when it's expanded */}
        {isExpanded ? nav : null}
      </div>
    </header>
  );
}

export default Header;

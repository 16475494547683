import { gql } from "@apollo/client";

export const ADD_SURVEY_RESULT = gql`
  mutation AddSurveyResult(
    $userId: Int
    $surveyNumber: Int
    $surveyResult: String
  ) {
    insert_users_survey(
      objects: [
        {
          user_id: $userId
          survey_number: $surveyNumber
          survey_result: $surveyResult
        }
      ]
    ) {
      returning {
        user_id
        survey_number
        survey_result
      }
    }
  }
`;

export const ADD_POPUP_ANSWER = gql`
  mutation AddPopupAnswer($popupId: Int!, $answerSlug: String!) {
    update_users_popup_by_pk(
      pk_columns: { id: $popupId }
      _set: { answer_slug: $answerSlug }
    ) {
      id
      answer_slug
    }
  }
`;

export const ADD_POPUP_QUESTION = gql`
  mutation AddPopupQuestion($userId: Int, $popupSlug: String!) {
    insert_users_popup_one(
      object: { user_id: $userId, popup_slug: $popupSlug }
    ) {
      id
    }
  }
`;

export const ADD_USER_LOG = gql`
  mutation AddUserLog($userId: Int, $status: String!) {
    insert_users_log_one(object: { user_id: $userId, status: $status }) {
      id
      status
    }
  }
`;

export const END_USER_PEFROMANCE = gql`
  mutation EndUser($userId: Int!) {
    update_users_by_pk(pk_columns: { id: $userId }, _set: { ended: true }) {
      id
      ended
    }
  }
`;
export default ADD_SURVEY_RESULT;

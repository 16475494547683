import React, { useState } from "react";
import { useMutation, useSubscription, gql } from "@apollo/client";
import PropTypes from "prop-types";

import Question from "./Question";
import AnimatedSection from "./AnimatedSection";

import { INTRO_QUESTIONS } from "./Question/consts";

import UserContext from "./user-context";
import { ADD_SURVEY_RESULT, ADD_POPUP_ANSWER } from "../mutations";
import { FormattedMessage } from "gatsby-plugin-intl";

const SUBSCRIBE_POPUP_BY_USER_ID = gql`
  subscription SubscribePopup($userId: Int!) {
    users_popup(
      where: { user_id: { _eq: $userId }, answer_slug: { _is_null: true } }
    ) {
      id
      popup_slug
    }
  }
`;

const Peforming = () => {
  return (
    <>
      <p className="text-2xl">
        <FormattedMessage id="performing" />
      </p>
    </>
  );
};

function ChoiceSection({ playNotification }) {
  const container = React.createRef();
  const userContext = React.useContext(UserContext);
  const [index, setIndex] = useState(
    userContext.lastSurveyNumber !== null ? userContext.lastSurveyNumber + 1 : 0
  );
  let popup;

  const { data: popups } = useSubscription(SUBSCRIBE_POPUP_BY_USER_ID, {
    variables: { userId: userContext.userId },
    onSubscriptionData: (opts) => {
      const subscriptionData = opts.subscriptionData;

      if (subscriptionData?.data?.users_popup?.length > 0) {
        // Play notification sound to users
        playNotification();
      }
    },
  });

  const fadeInContainer = (node) => {
    if (node) {
      node.classList.remove("animate__animated");

      setTimeout(() => {
        node.classList.add("animate__animated");
      }, 100);
    }
  };

  const [addSurveyResult, { error: mutationError }] = useMutation(
    ADD_SURVEY_RESULT,
    {
      onError: () => {
        alert("Oops, there's an error! Please try again");
      },
    }
  );

  const submitResult = (surveyNumber) => (option) => {
    const node = container.current;
    addSurveyResult({
      variables: {
        userId: userContext.userId,
        surveyNumber,
        surveyResult: option,
      },
    }).then(() => {
      setIndex(index + 1);
      fadeInContainer(node);
    });
  };

  const [addPopupAnswer, { error: popupMutationError }] = useMutation(
    ADD_POPUP_ANSWER,
    {
      onError: () => {
        alert("Oops, there's an error! Please try again");
      },
    }
  );

  if (popupMutationError)
    console.error("There's a mutation error", popupMutationError);

  const submitPopupAnswer = (popupId) => (option) => {
    const node = container.current;
    addPopupAnswer({
      variables: {
        popupId,
        answerSlug: option,
      },
    }).then(() => {
      fadeInContainer(node);
    });
  };

  if (popups && popups.users_popup.length > 0) {
    const popupItem = popups.users_popup[0];
    const popupSlug = popupItem.popup_slug;
    popup = (
      <Question slug={popupSlug} submit={submitPopupAnswer(popupItem.id)} />
    );
  }

  const introQuestion = INTRO_QUESTIONS[index];

  if (index > INTRO_QUESTIONS.length - 1 && !popup) {
    return <Peforming />;
  }

  if (mutationError) console.error("There's a mutation error", mutationError);

  // If there's i18n Id, we use intl to get the text
  let intro = null;

  if (introQuestion) {
    intro = <Question slug={introQuestion} submit={submitResult(index)} />;
  }

  return (
    <div>
      <AnimatedSection key={introQuestion ? introQuestion : popup.slug}>
        {intro ? intro : popup}
      </AnimatedSection>
    </div>
  );
}

ChoiceSection.propTypes = {
  playNotification: PropTypes.func,
};

export default ChoiceSection;

const QUESTIONS = [
  {
    slug: "speed",
    type: "choice",
    options: ["speed.too_slow", "speed.noop", "speed.too_fast"],
  },
  {
    slug: "are_you_ready",
    type: "choice",
    options: ["yes"],
  },
  {
    slug: "still_here",
    type: "choice",
    options: ["yes"],
  },
  {
    slug: "follow_me",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "your_presence",
    type: "choice",
    options: ["metoo", "ok2"],
  },
  {
    slug: "wait",
    type: "choice",
    options: ["wait.yes", "wait.no"],
  },
  {
    slug: "did_you_hear_it",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "did_you_hear_pingpong",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "did_you_hear_train",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "do_it_again",
    type: "choice",
    options: ["do_it_again.yes", "do_it_again.no"],
  },
  {
    slug: "wait_how_long",
    type: "choice",
    options: [
      "wait_how_long.10_secs",
      "wait_how_long.30_secs",
      "wait_how_long.60_secs",
    ],
  },
  {
    slug: "drink",
    type: "choice",
    options: ["drink.early", "drink.right_time", "drink.too_late"],
  },
  {
    slug: "map",
    type: "choice",
    options: ["map.yes", "map.no"],
  },
  {
    slug: "fork_path",
    type: "choice",
    options: ["fork_path.narrow", "fork_path.main_road"],
  },
  {
    slug: "umbrella",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "talk",
    type: "choice",
    options: ["talk.yes", "talk.no"],
  },
  {
    slug: "how_this_place",
    type: "choice",
    options: [
      "how_this_place.too_quiet",
      "how_this_place.alright",
      "how_this_place.too_noisy",
    ],
  },
  {
    slug: "photo",
    type: "choice",
    options: ["photo.yes", "photo.dont_mind", "photo.not"],
  },
  {
    slug: "photo_email",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "i_hear_you",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "i_agree",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "hmmm",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "run_red_light",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "almost_there",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "smell_good",
    type: "choice",
    options: ["nice", "ok2"],
  },
  {
    slug: "favourite_spot",
    type: "choice",
    options: ["nice", "ok2"],
  },
  {
    slug: "warm_weather",
    type: "choice",
    options: ["nice", "ok2"],
  },
  {
    slug: "smell_bad",
    type: "choice",
    options: ["smell_bad.is_it_really", "ok2", "smell_bad.move_from_there"],
  },
  {
    slug: "cold_weather",
    type: "choice",
    options: [
      "cold_weather.i_like_it_cooler",
      "ok2",
      "cold_weather.it_will_get_better",
    ],
  },
  {
    slug: "cigarette",
    type: "choice",
    options: ["cigarette.yes", "cigarette.join", "cigarette.no"],
  },
  {
    slug: "we_need_hurry_up",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "reconnecting",
    type: "choice",
    options: ["ok2"],
  },
  {
    slug: "come_closer",
    type: "choice",
    options: ["yes", "no"],
  },
  {
    slug: "out_of_time",
    type: "choice",
    options: [
      "out_of_time.early",
      "out_of_time.late",
      "out_of_time.there_is_time",
    ],
  },
  {
    slug: "we_have_arrived",
    type: "choice",
    options: ["ok"],
  },
  {
    slug: "finish",
    type: "choice",
    options: ["finish.finish_here", "finish.another_minute"],
  },
  {
    slug: "close_eyes_out",
    type: "choice",
    options: [],
  },
];

const getQuestioMap = (questions) => {
  const questionMap = {};
  questions.forEach(function (q) {
    questionMap[q.slug] = q;
  });

  return questionMap;
};

export const INTRO_QUESTIONS = [];

export const QUESTION_MAP = getQuestioMap(QUESTIONS);

export default QUESTIONS;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import { escapedNewLine } from "./utils";

const SLUGS = ["arrived", "end1", "end2", "end3"];

const Text = ({ textId }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: textId });

  const animatedPros = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 200,
    config: { duration: 1000 },
  });

  return (
    <animated.div style={animatedPros}>
      <div className="text-xl mt-4">{escapedNewLine(title)}</div>
    </animated.div>
  );
};

Text.propTypes = {
  textId: PropTypes.string,
};

const End = ({ onFinish, playNotification }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step === 0) {
      playNotification();
    }
  });

  return (
    <section className="text-center">
      <Text key={step} textId={SLUGS[step]} />

      <button
        className={
          "bg-gray-800 hover:bg-black text-lg md:text-xl text-white py-3 px-5 my-5 mx-10 md:mx-0 rounded transition-colors duration-1000"
        }
        onClick={() => {
          const nextStep = step + 1;
          if (nextStep >= SLUGS.length) {
            onFinish();
          } else {
            setStep(nextStep);
          }
        }}
      >
        <FormattedMessage id={"continue"} />
      </button>
    </section>
  );
};

End.propTypes = {
  onFinish: PropTypes.func,
  playNotification: PropTypes.func,
};

export default End;

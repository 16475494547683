import React, { useState } from "react";
import PropTypes from "prop-types";

import { useQuery, useMutation, useSubscription, gql } from "@apollo/client";
import UserContext from "./user-context";
import { ADD_USER_LOG } from "../mutations";
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl";
import { escapedNewLine, LOCALES_MAP_FOR_TIME } from "./utils";
import { LATE_THRESHOLD_IN_SECONDS } from "../config";

import Peformance from "./Peformance";
import Late from "./Late";
import Background from "./Background";
import End from "./End";
import Line from "./Line";
import AudioApp from "./AudioApp";
import AnimatedSection from "./AnimatedSection";

import coverPic from "../images/cover.jpg";

const NOTIFICATION_SOUND = "/bike-bell.mp3";

const Prepared = ({ onClickNext }) => {
  return (
    <section className="text-center">
      <p className="text-xl md:text-2xl">
        <FormattedMessage id="guide1" />
      </p>

      <p className="text-xl md:text-2xl mt-2 md:mt-3">
        <FormattedMessage id="guide2" />
      </p>

      <p className="text-xl md:text-2xl mt-2 md:mt-3">
        <FormattedMessage id="guide3" />
      </p>

      <button
        className={
          "animate-pulse bg-gray-800 hover:bg-black text-lg md:text-xl text-white py-2 px-5 my-5 mx-10 md:mx-0 rounded-lg transition-colors duration-1000"
        }
        onClick={onClickNext}
      >
        <FormattedMessage id="guide.confirm" />
      </button>

      <p className="text-lg mt-1 md:mt-2">
        <FormattedMessage id="guide.tech" />
      </p>
    </section>
  );
};

Prepared.propTypes = {
  onClickNext: PropTypes.func,
};

const StandBy = ({ startTime }) => {
  const intl = useIntl();
  const standByTech = intl.formatMessage(
    { id: "standby.tech" },
    {
      requirement: "latest Chrome/Safari/Firefox is recommended",
    }
  );

  return (
    <section className="text-center">
      <img
        alt="There's 2 ears"
        className="block mx-auto w-full md:w-2/3 my-2"
        src={coverPic}
      />

      <div className="text-xl mt-2">
        <FormattedMessage
          id="standby"
          values={{
            time: startTime,
          }}
        />
      </div>

      <div className="text-l mt-4">{escapedNewLine(standByTech)}</div>
    </section>
  );
};

StandBy.propTypes = {
  startTime: PropTypes.string,
};

const setupForStart = (time, callback) => {
  const hasWindow = typeof window === "undefined" ? false : true;
  if (!hasWindow) {
    return;
  }

  const checker = window.setInterval(() => {
    const now = new Date();

    if (now > time) {
      callback();
      clearInterval(checker);
    }
  }, 3000);
};

const GET_USER = gql`
  query UserQuery($key: uuid) {
    users(where: { key: { _eq: $key } }) {
      id
      email
      start_time
      logs {
        id
      }
      surveys(order_by: [{ survey_number: asc }]) {
        id
        survey_number
        survey_result
      }
    }
  }
`;

const CHECK_ENDED = gql`
  subscription CheckEndedQuery($key: uuid) {
    users(where: { key: { _eq: $key } }) {
      ended
    }
  }
`;

const ShowApp = ({ started, startTime, playNotification }) => {
  const userContext = React.useContext(UserContext);
  const [prepared, setPrepared] = useState(false);
  const [backgroundChecked, setBackgroundChecked] = useState(false);
  const [addUserLog, { error: mutationError }] = useMutation(ADD_USER_LOG);

  if (mutationError) {
    console.error("Add user log fail", mutationError);
  }

  const onReady = () => {
    addUserLog({
      variables: {
        userId: userContext.userId,
        status: "ENTER",
      },
    })
      .then(() => {
        setPrepared(true);
      })
      .catch(() => {
        alert("Failed, please try again!");
      });
  };

  let core = <Prepared onClickNext={onReady} />;

  if (prepared) {
    if (backgroundChecked) {
      core = <Peformance playNotification={playNotification} />;
    } else {
      core = (
        <Background
          onFinish={() => {
            addUserLog({
              variables: {
                userId: userContext.userId,
                status: "BACKGROUND_FINISHED",
              },
            })
              .then(() => {
                setBackgroundChecked(true);
              })
              .catch(() => {
                alert("Something went wrong, try again?");
              });
          }}
          playNotification={playNotification}
        />
      );
    }
  }

  return (
    <section className="w-full md:w-2/3 mx-auto my-2 md:my-4">
      {userContext.userId && !started && <StandBy startTime={startTime} />}

      {started && (
        <section className="flex flex-col">
          <AudioApp />
          <Line />
          <div style={{ minHeight: "400px" }} className="mt-0 md:mt-2">
            {core}
          </div>
        </section>
      )}
    </section>
  );
};

ShowApp.propTypes = {
  started: PropTypes.bool,
  startTime: PropTypes.string,
  playNotification: PropTypes.func,
};

const App = () => {
  let key = null;
  let lastSurveyNumber = null;
  const intl = useIntl();

  const timeFormat = new Intl.DateTimeFormat(
    LOCALES_MAP_FOR_TIME[intl.locale] || intl.locale,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZoneName: intl.locale === "zh-CN" ? "long" : "short",
    }
  );
  const [started, setStarted] = useState(false);

  if (typeof window !== `undefined`) {
    const urlParams = new URLSearchParams(window.location.search);
    key = urlParams.get("key");
  }

  const { loading: getUserLoading, error, data } = useQuery(GET_USER, {
    variables: { key },
    skip: !key,
  });

  const { data: checkEnded, loading: checkEndedLoading } = useSubscription(
    CHECK_ENDED,
    {
      variables: { key },
      skip: !key,
    }
  );

  const notification =
    typeof window !== `undefined` ? new window.Audio(NOTIFICATION_SOUND) : null;
  const playNotification = () => {
    if (notification) {
      const playPromise = notification.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            // Automatic playback started!
          })
          .catch(function (error) {
            console.error("fail to play", error);
          });
      }
    }
  };

  if (checkEnded && checkEnded.users && checkEnded.users[0].ended) {
    return (
      <AnimatedSection>
        <End
          playNotification={playNotification}
          onFinish={() => {
            navigate("/feedback/");
          }}
        />
      </AnimatedSection>
    );
  }

  if (getUserLoading || checkEndedLoading) {
    const reloadLabeltext = intl.formatMessage({ id: "reloadLabel" });
    const reloadTag = `<button class="inline-block px-1 py-1 text-white border border-white bg-gray-800 hover:bg-blue-700 rounded" onClick=window.location.reload()>
      ${reloadLabeltext}
    </button>`;

    const reloadText = intl.formatMessage(
      { id: "reload" },
      { reloadLabel: reloadTag }
    );

    return (
      <>
        <h2 className="text-2xl text-center">Loading...</h2>
        <p
          className="text-sm my-3"
          dangerouslySetInnerHTML={{ __html: reloadText }}
        />
      </>
    );
  }

  let user = null;

  if (error) {
    console.error(error);
  } else if (data) {
    user = data.users[0];
    if (user && user.surveys.length > 0) {
      lastSurveyNumber = user.surveys[user.surveys.length - 1].survey_number;
    }
  }

  if (!user) {
    if (typeof window !== "undefined") {
      navigate("/404/");
    }
    return null;
  }

  let displayTime = "";
  let initStarted = false;

  if (user) {
    const now = new Date();
    const startTime = new Date(user.start_time);
    const isLateOpening = (now - startTime) / 1000 > LATE_THRESHOLD_IN_SECONDS;

    if (isLateOpening && user.logs.length === 0) {
      return (
        <AnimatedSection>
          <Late />
        </AnimatedSection>
      );
    }

    if (now > startTime) {
      initStarted = true;
    } else {
      setupForStart(startTime, () => setStarted(true));
    }

    displayTime = timeFormat.format(startTime);
  }

  const peformanceStarted = initStarted || started;

  return (
    <UserContext.Provider
      value={{
        userId: user ? user.id : null,
        lastSurveyNumber,
        startTime: user ? user.start_time : null,
      }}
    >
      <AnimatedSection>
        <ShowApp
          started={peformanceStarted}
          startTime={displayTime}
          playNotification={playNotification}
        />
      </AnimatedSection>
    </UserContext.Provider>
  );
};

export default App;

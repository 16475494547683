import React from "react";
const hasWindow = typeof window === "undefined" ? false : true;

class PassInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenInputValue: hasWindow ? localStorage.getItem("token") : null,
    };
  }

  handleSave() {
    if (this.state.tokenInputValue && this.state.tokenInputValue.length > 0) {
      hasWindow && localStorage.setItem("token", this.state.tokenInputValue);
    }

    location.reload();
  }

  updateInputValue(evt) {
    this.setState({
      tokenInputValue: evt.target.value,
    });
  }

  render() {
    return (
      <div>
        <input
          type="text"
          placeholder="Enter your secert pass here"
          spellCheck="false"
          className="shadow appearance-none bg-red border rounded-lg w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline mb-5 md:mb-0 bg-red-100"
          value={this.state.tokenInputValue || ""}
          onChange={(evt) => this.updateInputValue(evt)}
        />
        <button
          className="bg-gray-800 hover:bg-gray-900 text-l md:text-lg text-white my-2 py-2 px-2 ml:0 md:ml-5  rounded"
          type="button"
          onClick={this.handleSave.bind(this)}
        >
          Save admin password
        </button>
      </div>
    );
  }
}

export default PassInput;

import React from "react";

import { escapedNewLine } from "./utils";

import { useIntl } from "gatsby-plugin-intl";

function Late() {
  const intl = useIntl();
  const arrivedText = intl.formatMessage({ id: "standby.late" });

  return (
    <div>
      <p className="text-2xl text-left">{escapedNewLine(arrivedText)}</p>
    </div>
  );
}

export default Late;

import React from "react";

const hasWindow = typeof window === "undefined" ? false : true;

export const escapedNewLine = (string) => {
  return string.split("\n").map((item, index) => {
    return index === 0 ? item : [<br key={index} />, item];
  });
};

export const getToken = () => {
  return hasWindow ? localStorage.getItem("token") : null;
};

export const LOCALES_MAP_FOR_TIME = {
  en: "en-GB",
};

export default escapedNewLine;

import React from "react";

function Line() {
  return (
    <hr
      className="w-full bg-gray-300 my-4"
      style={{
        height: "1px",
      }}
    />
  );
}

export default Line;

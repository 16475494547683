import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className="flex-1 w-full max-w-4xl px-4 py-4 mx-auto md:px-8 md:py-8">
        {children}
      </main>

      <footer className="bg-black">
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <p className="text-white">
            © 2021, by{" "}
            <a
              className="font-bold underline hover:text-blue-500 duration-500 transition-all"
              target="_blank"
              rel="noreferrer"
              href="https://notimportant.org/"
            >
              Li Song
            </a>{" "}
            &amp;{" "}
            <a
              className="font-bold underline hover:text-blue-500 duration-500 transition-all"
              target="_blank"
              rel="noreferrer"
              href="https://www.zhuomengting.com/"
            >
              Mengting Zhuo
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

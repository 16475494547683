import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function Button(props) {
  const { disabled } = props;

  return (
    <button
      className={classNames(
        "bg-gray-800 hover:bg-black text-lg md:text-xl text-white py-3 px-5 my-5 mx-10 md:mx-0 rounded-lg transition-colors duration-1000",
        {
          "opacity-60 cursor-not-allowed": disabled,
        }
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;

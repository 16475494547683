import "./src/css/style.css";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "./src/client";

const client = createApolloClient();

export const wrapRootElement = ({ element }) => {
  return <ApolloProvider client={client}>{element}</ApolloProvider>;
};

import React from "react";
import { Link } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/Button";

const SurveyCode = `
  <iframe src="https://www.wjx.top/vj/w3wIT6s.aspx?width=795&source=iframe&s=t&tm=1" width="100%" height="717" frameborder="0" style="overflow:auto"></iframe>
`;

function Feedbackpage() {
  return (
    <Layout>
      <SEO keywords={[``]} title="Feedback" />
      <section className="text-center">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: SurveyCode }}
        ></div>

        <div className="mt-10">
          <Link to="/about/">
            <Button>About 1000 Ears</Button>
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export default Feedbackpage;

import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "@apollo/client/link/context";

const hasWindow = typeof window === "undefined" ? false : true;

const retryLink = new RetryLink({
  delay: {
    initial: 150,
    max: 1000,
    jitter: true,
  },
  attempts: {
    max: 6,
    // eslint-disable-next-line no-unused-vars
    retryIf: (error, _) => {
      // eslint-disable-next-line no-unused-vars
      return !!error;
    },
  },
});

const httpLink = new HttpLink({
  uri: "https:" + process.env.GATSBY_API_SERVER,
});

let wsLink = httpLink;

const authLink = setContext((_, { headers }) => {
  const token = hasWindow ? localStorage.getItem("token") : null;

  if (token) {
    return {
      headers: {
        ...headers,
        "x-hasura-admin-secret": token,
      },
    };
  }

  return {
    headers,
  };
});

if (hasWindow) {
  wsLink = new WebSocketLink({
    uri: "wss:" + process.env.GATSBY_API_SERVER,
    options: {
      reconnect: true,
    },
  });
}

const link = split(
  ({ query }) => {
    if (!hasWindow) {
      return httpLink;
    }
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const createApolloClient = () => {
  return new ApolloClient({
    link: from([authLink, retryLink, link]),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;

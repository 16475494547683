import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={["1000ears"]} title="Home" />

      <section className="text-center">
        <a
          href="https://www.goethe.de/ins/cn/zh/ver.cfm?fuseaction=events.detail&event_id=22382611&fbclid=IwAR0KABwRf92bRwtCkU2xctHxyQRe49mFL94ojAE7FNRFQlPxa5VFI6sNRm0"
          className="underline text-lg"
        >
          1000 Ears at Beijing Fringe Festival, 16/10 - 17/10 2021
        </a>

        <img
          alt="1000 Ears poster"
          className="block mx-auto w-full my-4"
          src={"/poster.jpg"}
        />
      </section>
    </Layout>
  );
}

export default IndexPage;

import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, gql } from "@apollo/client";
import { useSpring, animated } from "react-spring";
import { escapedNewLine, LOCALES_MAP_FOR_TIME } from "./utils";
import classNames from "classnames";

const BACKGROUNDS = [
  "background1",
  "background2",
  "background3",
  "background4",
  "background5",
  "background6",
  "background7",
  "background8",
  "background9",
  "background10",
  "background11",
  "background12",
  "background13",
  "background14",
];

const WEATHER_QUERY = gql`
  query WeatherQuery($lang: Language!) {
    getCityByName(name: "London", config: { units: metric, lang: $lang }) {
      weather {
        temperature {
          feelsLike
        }
        summary {
          description
          title
        }
      }
    }
  }
`;

const Text = ({ textId, summary, time, temperature }) => {
  const intl = useIntl();
  const title = intl.formatMessage(
    { id: textId },
    {
      summary,
      time,
      temperature,
    }
  );

  const animatedPros = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 200,
    config: { duration: 1000 },
  });

  return (
    <animated.div style={animatedPros}>
      <div className="text-xl mt-4">{escapedNewLine(title)}</div>
    </animated.div>
  );
};

Text.propTypes = {
  textId: PropTypes.string,
  time: PropTypes.string,
  temperature: PropTypes.string,
  summary: PropTypes.string,
};

const Background = ({ onFinish, playNotification }) => {
  const [step, setStep] = useState(0);
  const now = new Date();
  const intl = useIntl();

  const timeFormat = new Intl.DateTimeFormat(
    LOCALES_MAP_FOR_TIME[intl.locale] || intl.locale,
    {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Europe/London",
    }
  );

  const { loading, error, data } = useQuery(WEATHER_QUERY, {
    // Converting the locale to lowerCase because the weather service using "zh_cn"
    variables: { lang: intl.locale.toLowerCase().replace("-", "_") },
  });

  let temperature = "unknown";
  let weatherSummary = "unknown";
  const time = timeFormat.format(now);

  if (!loading && !error && data) {
    const weather = data.getCityByName.weather;
    temperature = Math.round(weather.temperature.feelsLike).toString();
    const desc = (weatherSummary = weather.summary.description);
    weatherSummary = desc.charAt(0).toUpperCase() + desc.slice(1);
  }

  return (
    <section className="text-center">
      <Text
        key={step}
        textId={BACKGROUNDS[step]}
        time={time}
        temperature={temperature}
        summary={weatherSummary}
      />

      <button
        className={classNames(
          "bg-gray-800 hover:bg-black text-lg md:text-xl text-white py-2 px-5 my-5 mx-10 md:mx-0 rounded-lg transition-colors duration-1000",
          {
            // blink the button when it's first background
            "animate-pulse": step === 0,
          }
        )}
        onClick={() => {
          const nextStep = step + 1;
          if (nextStep >= BACKGROUNDS.length) {
            onFinish();
          } else {
            setStep(nextStep);

            // Chime step
            if (
              BACKGROUNDS[nextStep] === "background12" ||
              BACKGROUNDS[nextStep] === "background13"
            ) {
              playNotification();
            }
          }
        }}
      >
        <FormattedMessage id={"continue"} />
      </button>
    </section>
  );
};

Background.propTypes = {
  onFinish: PropTypes.func,
  playNotification: PropTypes.func,
};

export default Background;

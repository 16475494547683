import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useIntl } from "gatsby-plugin-intl";
import { QUESTION_MAP } from "./consts";
import InputQuestion from "./InputQuestion";
import { escapedNewLine } from "../utils";

export const getQuestion = (slug) => {
  return QUESTION_MAP[slug];
};

function ChoiceButton(props) {
  const { disabled } = props;

  return (
    <button
      className={classNames(
        "bg-gray-800 hover:bg-black text-lg md:text-xl ease-in-out text-white py-2 px-3 my-2 mx-10 md:mx-0 rounded-lg transition-all duration-1000",
        {
          "opacity-10 cursor-not-allowed": disabled,
        }
      )}
      onClick={props.onClick}
    >
      {props.option}
    </button>
  );
}

ChoiceButton.propTypes = {
  option: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

function Question(props) {
  const { slug, disabled, submit } = props;
  const question = getQuestion(slug);
  const intl = useIntl();
  const title = intl.formatMessage({ id: question.slug });

  if (question.type === "input") {
    return <InputQuestion title={title} submit={submit} />;
  }

  return (
    <>
      <h2 className="text-2xl text-left mb-2">{escapedNewLine(title)}</h2>

      <div className="flex flex-col md:flex-row justify-around">
        {question.options.map((optionSlug, index) => (
          <ChoiceButton
            key={index}
            disabled={disabled}
            option={intl.formatMessage({ id: optionSlug })}
            onClick={() => {
              if (!disabled) {
                submit(optionSlug);
              }
            }}
          />
        ))}
      </div>
    </>
  );
}

Question.propTypes = {
  slug: PropTypes.string,
  submit: PropTypes.func,
  disabled: PropTypes.bool,
};

Question.defaultProps = {
  disabled: false,
};

export default Question;

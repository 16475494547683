import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import profilePic from "../images/profile.jpg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

function AboutPage() {
  return (
    <Layout>
      <SEO keywords={[``]} title="About" />
      <h1>
        <FormattedMessage id="about" />
      </h1>

      <section>
        <img
          alt="Four ears"
          className="block mx-auto w-full my-4"
          src={profilePic}
        />

        <p>
          We are{" "}
          <a href="https://www.instagram.com/timelessclock/">
            Mengting(@timelessclock)
          </a>{" "}
          and{" "}
          <a href="https://www.instagram.com/slow.software/">
            Li Song(@slow.software)
          </a>
          .
        </p>

        <p>
          Our co-performers are{" "}
          <a href="https://www.instagram.com/hy.renders/">HY (@hy.renders )</a>{" "}
          and{" "}
          <a href="https://www.instagram.com/_yolin/">
            Flo Yu-ting Zhu (@_yolin )
          </a>
          .
        </p>

        <p>
          1000 Ears is a live audio performance for one person at a time. It is
          a journey about distance, connection and the intimate sounds.
        </p>

        <p>Follow our social media for announcement of next performances.</p>
      </section>

      <section>
        <p>
          我们是{" "}
          <a href="https://www.instagram.com/timelessclock/">
            卓梦婷(@timelessclock)
          </a>{" "}
          和{" "}
          <a href="https://www.instagram.com/slow.software/">
            李松(@slow.software)
          </a>
          .
        </p>

        <p>
          本次演出的表演者还有{" "}
          <a href="https://www.instagram.com/hy.renders/">HY (@hy.renders )</a>{" "}
          和 <a href="https://www.instagram.com/_yolin/">朱雨婷 (@_yolin )</a>。
        </p>

        <p>
          《一千只耳朵》，给一个人的声音表演，一场关于距离，联结，与亲密感的旅程。
        </p>

        <p>欢迎关注我们的Instagram，获得最新演出信息。</p>
      </section>
    </Layout>
  );
}

export default injectIntl(AboutPage);

import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Dashboard from "../components/Dashboard";
import PassInput from "../components/PassInput";

function DashboardPage() {
  return (
    <Layout>
      <SEO keywords={["1000ears"]} title="1000Ears" />

      <section className="text-center">
        <PassInput />
        <Dashboard />
      </section>
    </Layout>
  );
}

export default DashboardPage;

import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import App from "../components/App";

function PeformancePage() {
  return (
    <Layout>
      <SEO keywords={["1000ears"]} title="1000Ears Peformance" />

      <section className="text-center">
        <App />
      </section>
    </Layout>
  );
}

export default PeformancePage;

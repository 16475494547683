import React from "react";

const AUDIO_URL = "https://live2.1000ears.com/radio/8010/radio.mp3";

class AudioApp extends React.Component {
  constructor(props) {
    super(props);
    this.audioCtx = null;
    this.gainNode = null;
    this.audioRef = React.createRef();
  }

  onPlay = () => {
    if (this.audioCtx) {
      return;
    }

    const audioElement = this.audioRef.current;
    this.audioCtx = new ((typeof window !== "undefined" &&
      window.AudioContext) ||
      window.webkitAudioContext)();

    const source = this.audioCtx.createMediaElementSource(audioElement);
    const gainNode = this.audioCtx.createGain();
    this.gainNode = gainNode;
    source.connect(gainNode);
    gainNode.gain.value = 1;
    gainNode.connect(this.audioCtx.destination);
  };

  componentDidMount() {
    const audioElement = this.audioRef.current;
    audioElement.addEventListener("play", this.onPlay);
  }

  render() {
    return (
      <section>
        <div className="flex content-center justify-center">
          <audio
            className="w-10/12 md:w-2/3 mb-2 md:mb-4"
            src={AUDIO_URL}
            controls
            crossOrigin="anonymous"
            ref={this.audioRef}
          />
        </div>
      </section>
    );
  }
}

export default AudioApp;

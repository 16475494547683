import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { getToken } from "./utils";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useIntl } from "gatsby-plugin-intl";

import QUESTIONS from "./Question/consts";
import { ADD_POPUP_QUESTION, END_USER_PEFROMANCE } from "../mutations";

const UPCOMING_USERS = gql`
  query UpcomingUserQuery {
    users(where: { ended: { _eq: false } }, order_by: { start_time: asc }) {
      id
      email
      start_time
    }
  }
`;

const QuestionRow = ({ onClick, title }) => {
  return (
    <div className="flex flex-row justify-between py-1">
      <h3>{title}</h3>
      <button
        onClick={onClick}
        className="bg-black hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
      >
        Send
      </button>
    </div>
  );
};

QuestionRow.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

const initToken = getToken();

const Dashboard = () => {
  const { data: usersData, error: loadQuestionError } = useQuery(
    UPCOMING_USERS
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const intl = useIntl();

  const [addQuestion, { error: mutationError }] = useMutation(
    ADD_POPUP_QUESTION,
    {
      onError: (error) => {
        alert("Oops, send question failed!!", error);
      },
    }
  );

  const [endUserPefromance, { error: endUserError }] = useMutation(
    END_USER_PEFROMANCE
  );

  const users = usersData && usersData.users;

  if (loadQuestionError) {
    return <h1 className="bg-red-500">Admin pass is invalid!</h1>;
  }

  if (!initToken) {
    return <h1 className="bg-green-500">Please enter your admin pass</h1>;
  }

  if (!users) {
    return <h1>Loading🛠</h1>;
  }

  if (mutationError) {
    console.error("There's a mutation error", mutationError);
  }

  if (endUserError) {
    console.error("There's a mutation error", endUserError);
  }

  const sendQuestion = (popupSlug) => {
    if (!selectedUser) {
      alert("Please select an audience!! first");
      return;
    }

    addQuestion({
      variables: {
        userId: selectedUser.value,
        popupSlug,
      },
    }).then(
      () => {
        alert("Question sent successfully");
      },
      (error) => {
        alert("Question sent failed!!!", error.toString());
      }
    );
  };

  const userOptions = users.map((user) => {
    return {
      value: user.id,
      label: `Send to User ${user.email} starts at ${user.start_time}`,
    };
  });

  if (!selectedUser && userOptions.length > 0) {
    setSelectedUser(userOptions[0]);
  }

  return (
    <div>
      <h1>1000 Ears Dashboard</h1>

      <Select
        value={selectedUser}
        onChange={(selectedOption) => setSelectedUser(selectedOption)}
        options={userOptions}
        placeholder={"Choose the audience you want to control"}
      />

      <section className="my-3 flex px-0 md:px-20 flex-col">
        {QUESTIONS.map((question, index) => (
          <QuestionRow
            key={index}
            title={intl.formatMessage({ id: question.slug })}
            onClick={(event) => {
              sendQuestion(question.slug);
              event.preventDefault();
            }}
          />
        ))}
      </section>

      <button
        onClick={() => {
          if (!selectedUser) {
            alert("Please Choose an audience first");
          }

          if (
            !confirm(
              "Do you want to end the Performance for this audience? You can't undo this"
            )
          ) {
            return;
          }

          endUserPefromance({
            variables: {
              userId: selectedUser.value,
            },
          })
            .then(() => {
              alert("End successfully");
            })
            .catch((error) => {
              alert("End failed!!!", error.toString());
            });
        }}
        className="bg-red-700 text-white font-extrabold py-2 px-3 rounded"
      >
        End Performance!
      </button>
    </div>
  );
};

export default Dashboard;

import React from "react";
import PropTypes from "prop-types";

import { useSpring, animated } from "react-spring";

const AnimatedSection = ({ children }) => {
  const animatedPros = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 200,
    config: { duration: 1000 },
  });

  return <animated.div style={animatedPros}>{children}</animated.div>;
};

AnimatedSection.propTypes = {
  children: PropTypes.any,
};

export default AnimatedSection;

import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import notFoundPic from "../images/not_found.jpg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <img alt="No ear" className="block mx-auto w-full" src={notFoundPic} />

        <h2 className="text-2xl font-bold inline-block my-8 p-3">
          There is no ear
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;

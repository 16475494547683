import React from "react";
import PropTypes from "prop-types";

function InputQuestion(props) {
  let input;

  return (
    <div className="my-10 flex flex-col mx-10 md:mx-0">
      <h2 className="text-2xl text-bold mb-4">{props.title}</h2>

      <div className="flex flex-col md:flex-row justify-between w-full my-2">
        <input
          type="text"
          spellCheck="false"
          className="shadow appearance-none border rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5 md:mb-0"
          ref={(node) => {
            input = node;
          }}
        />

        <button
          className="bg-gray-800 hover:bg-gray-900 text-lg md:text-lg text-white py-4 px-5 ml:0 md:ml-5 rounded-lg"
          type="button"
          onClick={() => props.submit(input.value)}
        >
          Enter
        </button>
      </div>
    </div>
  );
}

InputQuestion.propTypes = {
  submit: PropTypes.func,
  title: PropTypes.string,
};

export default InputQuestion;
